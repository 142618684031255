import red from '@material-ui/core/colors/red'

const hearts = []

const loveFaceImage = new Image()
const aubergineImage = new Image()
const peachImage = new Image()

loveFaceImage.src = 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/240/apple/237/smiling-face-with-heart-shaped-eyes_1f60d.png'
aubergineImage.src = 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/240/apple/237/aubergine_1f346.png'
peachImage.src = 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/240/apple/237/peach_1f351.png'

const heartTypeToImage = {
  'love-face-emoji': loveFaceImage,
  'aubergine-emoji': aubergineImage,
  'peach-emoji': peachImage,
}

function handleCanvas(canvas, initialCircleRadius, expand, endCallback) {
  const { sin, cos, PI } = Math
  const TAU = 2 * PI

  const _ = canvas.getContext('2d')

  const width = canvas.width = window.innerWidth
  const height = canvas.height = window.innerHeight
  const backgroundColor = red[500]
  const contentColor = 'white'

  /* ---
    Data
  --- */

  let circleRadius = initialCircleRadius
  let heartsAngle = 0

  const maxCircleRadius = Math.sqrt((width / 2) ** 2 + (height / 2) ** 2)
  const heartMinRadius = 24
  const heartMaxRadius = 48
  const heartMargin = 48
  const heartSpeed = 1

  function createHeart() {
    const type = pickRandom(['heart', 'heart', 'love-face-emoji', 'aubergine-emoji', 'peach-emoji'])
    const heartRadius = randomRange(heartMinRadius, heartMaxRadius)

    const heart = {
      type,
      radius: heartRadius,
      a: heartsAngle + pickRandom([0, PI / 4, PI / 2, PI * 3 / 4, PI, PI * 5 / 4, PI * 3 / 2, PI * 7 / 4]),
    }

    if (type === 'heart') {
      const heartSpan = heartRadius * 2 / Math.sqrt(2)
      const workingBirthRadius = initialCircleRadius - 2 * heartSpan - heartRadius

      Object.assign(heart, {
        span: heartSpan,
        r: workingBirthRadius,
      })
    }
    else {
      heart.radius *= 2
      const heartSpan = heartRadius * 1.5
      const workingBirthRadius = initialCircleRadius - 2 * heartSpan - heartRadius

      Object.assign(heart, {
        span: heartSpan,
        r: workingBirthRadius,
        image: heartTypeToImage[type],
      })
    }

    if (
      !hearts.some(h => areCirclesIntersecting(
        width / 2 - (heart.r + heart.span) * sin(heart.a),
        height / 2 - (heart.r + heart.span) * cos(heart.a),
        heart.span + heartMargin,
        width / 2 - (h.r + h.span) * sin(h.a),
        height / 2 - (h.r + h.span) * cos(h.a),
        h.span,
      ))
    ) {
      return heart
    }
  }

  /* ---
    Update
  --- */

  function update() {
    if (expand) {
      if (circleRadius < maxCircleRadius) {
        circleRadius += circleRadius * circleRadius * 0.00002 + 1
      }
      else {
        endCallback()
      }
    }
    else {
      const heart = createHeart()

      if (heart) {
        hearts.push(heart)
      }
    }

    hearts.forEach(h => h.r += heartSpeed)

    heartsAngle += TAU / 360 / 12

    // TODO: remove hearts
  }

  /* ---
    Draw
  --- */

  function draw() {
    _.fillStyle = backgroundColor
    _.fillRect(0, 0, width, height)

    hearts.forEach(({ type, r, a, span, radius, image }) => {
      if (type === 'heart') {
        drawHeart(
          width / 2 - r * sin(a),
          height / 2 - r * cos(a),
          a,
          span,
          radius
        )
      }
      else {
        drawImage(
          width / 2 - r * sin(a),
          height / 2 - r * cos(a),
          a,
          radius,
          image,
        )
      }
    })

    drawCircle(width / 2, height / 2, circleRadius)
  }

  function drawCircle(x, y, r) {
    _.fillStyle = contentColor
    _.beginPath()
    _.arc(x, y, r, 0, TAU)
    _.closePath()
    _.fill()
  }

  function drawHeart(x, y, a, span, radius) {
    _.fillStyle = contentColor
    _.save()
    _.translate(x, y)
    _.rotate(-a)
    _.translate(-x, -y)
    _.beginPath()
    _.moveTo(x, y)
    _.lineTo(x + span, y - span)
    _.lineTo(x, y - 2 * span)
    _.lineTo(x - span, y - span)
    _.closePath()
    _.fill()
    _.beginPath()
    _.arc(x + span / 2, y - 3 * span / 2, radius, 0, TAU)
    _.closePath()
    _.fill()
    _.beginPath()
    _.arc(x - span / 2, y - 3 * span / 2, radius, 0, TAU)
    _.closePath()
    _.fill()
    _.restore()
  }

  function drawImage(x, y, a, radius, image) {
    const xx = x - 2 * radius * sin(a)
    const yy = y - 2 * radius * cos(a)

    _.save()
    _.translate(xx, yy)
    _.rotate(-a)
    _.translate(-xx, -yy)
    _.drawImage(image, xx, yy, radius, radius)
    _.restore()
    // _.fillStyle = 'blue'
    // _.beginPath()
    // _.arc(xx, yy, 3, 0, TAU)
    // _.closePath()
    // _.fill()
  }

  /* ---
    Utils
  --- */

  function randomRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  function pickRandom(array) {
    return array[Math.floor(Math.random() * array.length)]
  }

  function areCirclesIntersecting(x1, y1, r1, x2, y2, r2) {
    return (x2 - x1) ** 2 + (y2 - y1) ** 2 < (r1 + r2) ** 2
  }

  /* ---
    Loop
  --- */

  let stopped = false

  function tick() {
    update()
    draw()

    if (stopped) return

    window.requestAnimationFrame(tick)
  }

  window.requestAnimationFrame(tick)

  return () => stopped = true
}

export default handleCanvas
