import React, { useRef, useEffect } from 'react'

import handleCanvas from './handleCanvas'

function CirclesDance({ initialRadius, expand, endCallback }) {
  const canvasRef = useRef()

  useEffect(
    () => handleCanvas(canvasRef.current, initialRadius, expand, endCallback),
    [initialRadius, expand, endCallback]
  )

  return (
    <canvas ref={canvasRef} />
  )
}

export default CirclesDance
