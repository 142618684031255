import React from 'react'
import { createPaginationContainer } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'

import extractNodes from '../../utils/extractNodes'

function TagsManager({ resource, relay }) {
  const tags = extractNodes(resource.tags)

  function loadMore() {
    if (!relay.hasMore() || relay.isLoading()) {
      return
    }

    relay.loadMore(
      12,
      error => console.error(error),
    )
  }

  return (
    <Container>
      {tags.map(({ id, name, description }) => (
        <Box key={id}>
          <strong>{name}</strong> - {description}
        </Box>
      ))}
      <Box mt={2}>
        <Button onClick={loadMore}>
          Load more
        </Button>
      </Box>
    </Container>
  )
}

export default createPaginationContainer(
  TagsManager,
  {
    resource: graphql`
      fragment TagsManager_resource on Resource
      @argumentDefinitions(
        count: {type: "Int", defaultValue: 12}
        cursor: {type: "String"}
      ) {
        tags(
          first: $count
          after: $cursor
        ) @connection(key: "TagsManager_tags") {
          edges {
            node {
              id
              name
              description
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.resource && props.resource.tags
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
      }
    },
    query: graphql`
      query TagsManagerPaginationQuery(
        $count: Int!
        $cursor: String
      ) {
        resource {
          ...TagsManager_resource @arguments(count: $count, cursor: $cursor)
        }
      }
    `,
  }
)
