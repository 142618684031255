import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import environment from '../relay-environment'
import handleMutationCompletion from '../utils/handleMutationCompletion'

function createUser({
  email,
  pseudonyme,
  password,
}) {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment,
      {
        mutation: graphql`
          mutation createUserMutation($input: createUserInput!) {
            createUser(input: $input) {
              viewer {
                id
              }
            }
          }
        `,
        variables: {
          input: {
            email,
            pseudonyme,
            password,
          },
        },
        onCompleted: handleMutationCompletion(resolve, reject),
        onError: error => reject(error),
      },
    )
  })
}

export default createUser
