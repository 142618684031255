import { authorizationTokenLocalStorageKey } from '../configuration'
import history from '../history'

function handleMutationCompletion(resolve, reject) {
  return (response, errors) => {
    if (errors && errors.length) {
      if (errors[0].message === 'Unauthorized') {
        localStorage.removeItem(authorizationTokenLocalStorageKey)
        history.push('/')

        return
      }

      return reject(errors)
    }

    return resolve(response)
  }
}

export default handleMutationCompletion
