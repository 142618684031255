import React from 'react'

import { phi } from '../../utils/mathUtils'

function RackItemPlaceholder({ width }) {
  return (
    <div
      style={{
        minWidth: `calc(${width}px - 2px)`,
        maxWidth: `calc(${width}px - 2px)`,
        height: `calc((${width}px - 2px)/ ${phi})`,
        marginRight: 2,
        borderRadius: 4,
        backgroundColor: 'lightgrey',
      }}
    />
  )
}

export default RackItemPlaceholder
