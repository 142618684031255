import React from 'react'
import {
  Router as ReactRouter,
  Switch,
  Route,
} from 'react-router-dom'

import history from './history'

import AgeVerificationCurtain from './components/AgeVerificationCurtain'

import Home from './scenes/Home'
import SignUp from './scenes/SignUp'
import TagsManager from './scenes/TagsManager'
import NotFound from './scenes/NotFound'

function Router() {

  return (
    <AgeVerificationCurtain>
      <ReactRouter history={history}>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/sign-up">
            <SignUp />
          </Route>
          <Route exact path="/tags-manager">
            <TagsManager />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </ReactRouter>
    </AgeVerificationCurtain>
  )
}

export default Router
