import { createMuiTheme } from '@material-ui/core/styles'
import blue from '@material-ui/core/colors/blue'
import green from '@material-ui/core/colors/green'
import orange from '@material-ui/core/colors/orange'
import red from '@material-ui/core/colors/red'
import yellow from '@material-ui/core/colors/yellow'
import grey from '@material-ui/core/colors/grey'

const theme = createMuiTheme({
  palette: {
    primary: red,
    secondary: blue,
    blue,
    green,
    orange,
    red,
    grey,
    yellow,
  },
  typography: {
    h1: {
      fontFamily: 'Comfortaa, Roboto, sans-serif',
    },
    h2: {
      fontFamily: 'Comfortaa, Roboto, sans-serif',
    },
    h3: {
      fontFamily: 'Comfortaa, Roboto, sans-serif',
    },
    h4: {
      fontFamily: 'Comfortaa, Roboto, sans-serif',
    },
    h5: {
      fontFamily: 'Comfortaa, Roboto, sans-serif',
    },
    h6: {
      fontFamily: 'Comfortaa, Roboto, sans-serif',
    },
  },
})

export default theme
