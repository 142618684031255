import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import FacebookLogin from 'react-facebook-login'
import { GoogleLogin } from 'react-google-login'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import createUser from '../../mutations/createUser'
import signInUserWithFacebook from '../../mutations/signInUserWithFacebook'

function SignUp() {
  const [email, setEmail] = useState('')
  const [pseudonyme, setPseudonyme] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()

  async function handleSubmit(event) {
    event.preventDefault()

    await createUser({
      email,
      pseudonyme,
      password,
    })
  }

  function handleFacebookClick() {
    setIsLoading(true)
  }

  async function handleFacebookResponse(response) {
    if (!response) {
      setIsLoading(false)

      return
    }

    await signInUserWithFacebook({
      facebookUserId: response.id,
      email: response.email,
      gender: response.gender,
      birthday: response.birthday,
      pictureUrl: response.picture.data.url,
    })

    history.push('/')
  }

  async function handleGoogleSuccessResponse(response) {
    console.log('response', response)
  }

  function handleGoogleFailureResponse(response) {
    console.log('handleGoogleFailureResponse', response)
  }

  if (isLoading) {
    return (
      <Container maxWidth="md">
        <CircularProgress />
      </Container>
    )
  }

  return (
    <Container maxWidth="md">
      <Box mt={8}>
        <Typography
          variant="h3"
          component="h1"
        >
          Sign up
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box>
            <TextField
              label="Email"
              value={email}
              onChange={event => setEmail(event.target.value)}
            />
          </Box>
          <Box>
            <TextField
              label="Pseudonyme"
              value={pseudonyme}
              onChange={event => setPseudonyme(event.target.value)}
            />
          </Box>
          <Box>
            <TextField
              label="Password"
              type="password"
              value={password}
              onChange={event => setPassword(event.target.value)}
            />
          </Box>
          <Box>
            <Button type="sumbit">
              Sign up
            </Button>
          </Box>
        </form>
      </Box>
      <Box mt={6}>
        <FacebookLogin
          autoLoad
          appId="1088597931155576"
          fields="email,picture,gender,birthday"
          onClick={handleFacebookClick}
          callback={handleFacebookResponse}
        />
      </Box>
      <Box mt={2}>
        <GoogleLogin
          isSignedIn
          clientId="921496591795-du5m8t0htphk5t1a8t2ta2q0g0j0n8sv.apps.googleusercontent.com"
          buttonText="Login with Google"
          onSuccess={handleGoogleSuccessResponse}
          onFailure={handleGoogleFailureResponse}
          cookiePolicy="single_host_origin"
        />
      </Box>
    </Container>
  )
}

export default SignUp
