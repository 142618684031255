import React, { useRef, useEffect, useState } from 'react'

import Box from '@material-ui/core/Box'

import { phi } from '../../utils/mathUtils'

import RackItem from './RackItem'
import RackItemPlaceholder from './RackItemPlaceholder'
import Chevron from './Chevron'

const nItems = 6
const margin = 64

function Rack({ items }) {
  const rootRef = useRef()
  const [width, setWidth] = useState(0)
  const [page, setPage] = useState(0)
  const [isBackToBeginning, setIsBackToBeginning] = useState(false)
  const [isMouseOver, setIsMouseOver] = useState(false)
  const [nextPage, setNextPage] = useState(0)

  const isOverflowing = items.length > nItems
  const workingWidth = width - 2 * margin
  const offset = isOverflowing
    ? page === nextPage
      ? `calc(-200% + ${workingWidth / nItems}px + ${margin}px)`
      : `calc(-200% + ${page - nextPage} * 100% + ${workingWidth / nItems}px - ${(2 * (page - nextPage) - 1) * margin}px)`
    : margin

  const selectedItems = []

  if (isOverflowing) {
    for (let i = -2 * nItems; i < 3 * nItems; i++) {
      let index = i < 0
        ? (((items.length + i) % items.length) + page * nItems) % items.length
        : ((i % items.length) + page * nItems) % items.length

      if (index < 0) index += items.length

      selectedItems.push(items[index])
    }
  }
  else {
    for (let i = 0; i < nItems; i++) {
      selectedItems.push(items[i])
    }
  }

  useEffect(() => {
    if (rootRef.current) {
      setWidth(rootRef.current.clientWidth)
    }
  }, [])

  useEffect(() => {
    if (nextPage !== page) {
      setTimeout(() => {
        setPage(nextPage)
      }, 510)
    }
  })

  function handleMouseMove() {
    if (!isMouseOver) {
      setIsMouseOver(true)
    }
  }

  function handleMouseEnter() {
    setIsMouseOver(true)
  }

  function handleMouseLeave() {
    setIsMouseOver(false)
  }

  function handleLeftCLick() {
    if (nextPage !== page) return

    if (page === 1) setIsBackToBeginning(true)

    setNextPage(page - 1)
  }

  function handleRightClick() {
    if (nextPage !== page) return

    setNextPage(page + 1)
  }

  return (
    <Box
      ref={rootRef}
      width="100%"
      height={width / nItems / phi}
      position="relative"
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box
        position="absolute"
        left={offset}
        display="flex"
        minWidth="100%"
        style={{
          transition: page === nextPage ? null : 'left 0.5s ease-in-out',
        }}
      >
        {selectedItems.map((item, i) => {
          if (item) {
            return (
              <RackItem
                key={item.id + i}
                item={item}
                width={workingWidth / nItems}
                visible={!isOverflowing || isBackToBeginning || page !== 0 || i >= 2 * nItems}
              />
            )
          }

          return (
            <RackItemPlaceholder
              key={i}
              width={workingWidth / nItems}
            />
          )
        })}
      </Box>
      {isOverflowing && (page !== 0 || isBackToBeginning) && isMouseOver && (
        <Chevron
          direction="left"
          onClick={handleLeftCLick}
        />
      )}
      {isOverflowing && isMouseOver && (
        <Chevron
          direction="right"
          onClick={handleRightClick}
        />
      )}
    </Box>
  )
}

export default Rack
