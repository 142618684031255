import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { phi } from '../../utils/mathUtils'

function RackItem({ item, width, visible }) {
  const [enhanced, setEnhanced] = useState(false)
  const [enhanceTimeoutId, setEnhanceTimeoutId] = useState(null)

  function setUpEnhanceTimeout() {
    if (!enhanced && enhanceTimeoutId === null) {
      setEnhanceTimeoutId(
        setTimeout(() => {
          setEnhanced(true)
        }, 700)
      )
    }
  }

  function handleMouseMove() {
    setUpEnhanceTimeout()
  }

  function handleMouseEnter() {
    setUpEnhanceTimeout()
  }

  function handleMouseLeave() {
    clearTimeout(enhanceTimeoutId)
    setEnhanceTimeoutId(null)
    setEnhanced(false)
  }

  return (
    <Box
      style={{
        position: 'relative',
        minWidth: `calc(${width}px - 2px)`,
        maxWidth: `calc(${width}px - 2px)`,
        height: `calc((${width}px - 2px)/ ${phi})`,
        marginRight: 2,
        borderRadius: 4,
        visibility: visible ? 'visible' : 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        cursor: 'pointer',
      }}
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      component={RouterLink}
      to={`/course/${item.id}`}
    >
      <div
        style={{
          zIndex: enhanced ? 99999 : 10,
          width: '100%',
          height: '100%',
          backgroundImage: `url("${item.pictureUrl}")`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: '50% 50%',
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          borderBottomLeftRadius: enhanced ? 0 : 4,
          borderBottomRightRadius: enhanced ? 0 : 4,
          transform: `scale(${enhanced ? 1.25 : 1})`,
          transition: `transform 0.1s ease-out, border-radius 0.1s ease-out${enhanced ? '' : ', z-index 0s linear 0.1s'}`,
          flexShrink: 0,
        }}
      />
      <div
        style={{
          zIndex: enhanced ? 99999 : 10,
          position: 'absolute',
          top: enhanced ? '120.5%' : '100%',
          width: '100%',
          height: width - (width - 2) / phi,
          opacity: enhanced ? 1 : 0,
          visibility: enhanced ? 'visible' : 'hidden',
          transform: `scale(${enhanced ? 1.25 : 1})`,
          transition: 'transform 0.1s ease-out, opacity 0.1s ease-out, top 0.1s ease-out',
          color: 'white',
          padding: 8,
          backgroundColor: '#141414',
        }}
      >
        <Typography
          noWrap
          variant="h2"
          title={item.name}
          style={{
            fontSize: 16,
          }}
        >
          {item.name}
        </Typography>
        <Box mt={0.5}>
          <Typography
            paragraph
            style={{
              fontSize: 12,
            }}
          >
            {ellipsis(item.description, 120)}
          </Typography>
        </Box>
      </div>

    </Box>
  )
}

function ellipsis(text, maxLength) {
  return `${text.substring(0, maxLength).trim()}...`
}

export default RackItem
