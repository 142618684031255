/* eslint-disable */

window.fbAsyncInit = function () {
  FB.init({
    appId: '611381879549872',
    cookie: true,
    xfbml: true,
    version: 'v8.0',
  })

  FB.AppEvents.logPageView()
}

;(function (d, s, id) {
  let js; const
    fjs = d.getElementsByTagName(s)[0]
  if (d.getElementById(id)) {
    return
  }
  js = d.createElement(s); js.id = id
  js.src = 'https://connect.facebook.net/en_US/sdk.js'
  fjs.parentNode.insertBefore(js, fjs)
}(document, 'script', 'facebook-jssdk'))

export function getFacebookLoginStatus() {
  return new Promise(resolve => {
    FB.getLoginStatus(resolve)
  })
}

export function getFacebookUserInformations() {
  return new Promise(resolve => {
    FB.api('/me', { fields: 'email,gender,birthday' }, resolve)
  })
}
