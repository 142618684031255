import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import AddIcon from '@material-ui/icons/Add'

import PillButton from '../../components/PillButton'

function CreateButton() {
  const [open, setOpen] = useState(false)
  const _ = useStyles()

  function handleCreateClick() {
    setOpen(open => !open)
  }

  function handleClose() {
    setOpen(false)
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={_.root}>
        <div
          style={{
            opacity: open ? 0 : 1,
            visibility: open ? 'hidden' : 'visible',
            transition: `opacity 0.2s ease-out${open ? ', visibility 0s linear 0.2s' : ''}`,
            alignItems: 'flex-end',
          }}
        >
          <PillButton onClick={handleCreateClick}>
            <AddIcon
              fontSize="small"
              color="primary"
              style={{
                transition: 'color 0.2s ease-out',
                marginLeft: -4,
              }}
            />
            <Box ml={0.5}>
              Create content
            </Box>
          </PillButton>
        </div>
        <div
          className={_.container}
          style={{
            opacity: open ? 1 : 0,
            visibility: open ? 'visible' : 'hidden',
            transition: `opacity 0.2s ease-out${open ? '' : ', visibility 0s linear 0.2s'}`,
          }}
        >
          <PillButton>
            Course
          </PillButton>
          <Box ml={1}>
            <PillButton>
              Article
            </PillButton>
          </Box>
        </div>
      </div>
    </ClickAwayListener>
  )
}

const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  container: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    bottom: 0,
    right: 0,
  },
})

export default CreateButton
