import React from 'react'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import Rack from '../../components/Rack'
import RackTitle from '../../components/RackTitle'
import PillButton from '../../components/PillButton'
import CreateButton from './CreateButton'

function Home() {
  return (
    <div
      style={{
        maxWidth: '100%',
        minHeight: '100vh',
        overflowX: 'hidden',
      }}
    >
      <Box
        mt={6}
        ml="66px"
        display="flex"
        alignItems="center"
      >
        <Typography style={{ fontWeight: 500 }}>
          Go to:
        </Typography>
        <Box ml={2}>
          <PillButton>
            Courses
          </PillButton>
        </Box>
        <Box ml={2}>
          <PillButton>
            Articles
          </PillButton>
        </Box>
        <Box ml={2}>
          <PillButton>
            Conversations
          </PillButton>
        </Box>
        <Box flexGrow={1} />
        <Box mr="66px">
          <CreateButton />
        </Box>
      </Box>
      <Box mt={4}>
        <RackTitle title="Courses on sexual education" />
        <Rack
          items={[
            {
              id: '1',
              name: 'Sex for beginners and more, like a long title and such and such and such',
              pictureUrl: 'https://u3c4j6t8.stackpathcdn.com/wp-content/uploads/2017/05/Shirtless-Guy-with-Girl_567.png',
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
              id: '2',
              name: 'The Art of Cunnilingus',
              pictureUrl: 'https://cakeheadlovesevil.files.wordpress.com/2011/04/cum-tongue-cakes-1.jpg',
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
              id: '3',
              name: 'The Art of Fellatio',
              pictureUrl: 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/socialmedia/apple/237/aubergine_1f346.png',
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
              id: '4',
              name: 'Sex for beginners',
              pictureUrl: 'https://u3c4j6t8.stackpathcdn.com/wp-content/uploads/2017/05/Shirtless-Guy-with-Girl_567.png',
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
              id: '5',
              name: 'The Art of Cunnilingus',
              pictureUrl: 'https://cakeheadlovesevil.files.wordpress.com/2011/04/cum-tongue-cakes-1.jpg',
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
              id: '6',
              name: 'Sex for beginners',
              pictureUrl: 'https://u3c4j6t8.stackpathcdn.com/wp-content/uploads/2017/05/Shirtless-Guy-with-Girl_567.png',
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
              id: '7',
              name: 'The Art of Fellatio',
              pictureUrl: 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/socialmedia/apple/237/aubergine_1f346.png',
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
              id: '8',
              name: 'The Art of Cunnilingus',
              pictureUrl: 'https://cakeheadlovesevil.files.wordpress.com/2011/04/cum-tongue-cakes-1.jpg',
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
              id: '9',
              name: 'The Art of Fellatio',
              pictureUrl: 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/socialmedia/apple/237/aubergine_1f346.png',
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
              id: '10',
              name: 'Sex for beginners',
              pictureUrl: 'https://u3c4j6t8.stackpathcdn.com/wp-content/uploads/2017/05/Shirtless-Guy-with-Girl_567.png',
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
              id: '11',
              name: 'The Art of Fellatio',
              pictureUrl: 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/socialmedia/apple/237/aubergine_1f346.png',
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
              id: '12',
              name: 'The Art of Cunnilingus',
              pictureUrl: 'https://cakeheadlovesevil.files.wordpress.com/2011/04/cum-tongue-cakes-1.jpg',
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
              id: '13',
              name: 'Sex for beginners',
              pictureUrl: 'https://u3c4j6t8.stackpathcdn.com/wp-content/uploads/2017/05/Shirtless-Guy-with-Girl_567.png',
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
              id: '14',
              name: 'Sex for beginners',
              pictureUrl: 'https://u3c4j6t8.stackpathcdn.com/wp-content/uploads/2017/05/Shirtless-Guy-with-Girl_567.png',
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
              id: '15',
              name: 'The Art of Fellatio',
              pictureUrl: 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/socialmedia/apple/237/aubergine_1f346.png',
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
              id: '16',
              name: 'The Art of Cunnilingus',
              pictureUrl: 'https://cakeheadlovesevil.files.wordpress.com/2011/04/cum-tongue-cakes-1.jpg',
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
              id: '17',
              name: 'Sex for beginners',
              pictureUrl: 'https://u3c4j6t8.stackpathcdn.com/wp-content/uploads/2017/05/Shirtless-Guy-with-Girl_567.png',
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
              id: '18',
              name: 'The Art of Cunnilingus',
              pictureUrl: 'https://cakeheadlovesevil.files.wordpress.com/2011/04/cum-tongue-cakes-1.jpg',
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
              id: '19',
              name: 'The Art of Fellatio',
              pictureUrl: 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/socialmedia/apple/237/aubergine_1f346.png',
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
              id: '20',
              name: 'The Art of Fellatio',
              pictureUrl: 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/socialmedia/apple/237/aubergine_1f346.png',
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
              id: '21',
              name: 'The Art of Fellatio',
              pictureUrl: 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/socialmedia/apple/237/aubergine_1f346.png',
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
          ]}
        />
      </Box>
      <Box mt={4}>
        <RackTitle title="Articles on romance" />
        <Rack
          items={[
            {
              id: '1',
              name: 'Sex for beginners',
              pictureUrl: 'https://u3c4j6t8.stackpathcdn.com/wp-content/uploads/2017/05/Shirtless-Guy-with-Girl_567.png',
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
              id: '2',
              name: 'The Art of Cunnilingus',
              pictureUrl: 'https://cakeheadlovesevil.files.wordpress.com/2011/04/cum-tongue-cakes-1.jpg',
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
              id: '3',
              name: 'The Art of Fellatio',
              pictureUrl: 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/socialmedia/apple/237/aubergine_1f346.png',
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
          ]}
        />
      </Box>
      <Box mt={4}>
        <RackTitle title="Conversations about sex on any topic" />
        <Rack
          items={[
            {
              id: '1',
              name: 'Sex for beginners',
              pictureUrl: 'https://u3c4j6t8.stackpathcdn.com/wp-content/uploads/2017/05/Shirtless-Guy-with-Girl_567.png',
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
          ]}
        />
      </Box>
    </div>
  )
}

export default Home
