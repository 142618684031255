// eslint-disable-next-line
import { Environment, Network, RecordSource, Store } from 'relay-runtime'

import { authorizationTokenLocalStorageKey, graphqlServiceHost } from './configuration'

async function fetchQuery(
  operation,
  variables,
) {
  const token = localStorage.getItem(authorizationTokenLocalStorageKey)

  const headers = {
    'Content-Type': 'application/json',
  }

  if (token) {
    headers.Authorization = `bearer ${token}`
  }

  const response = await fetch(graphqlServiceHost, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
  })

  return response.json()
}

const environment = new Environment({
  network: Network.create(fetchQuery),
  store: new Store(new RecordSource()),
})

export default environment
