import React, { useState } from 'react'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { makeStyles, useTheme } from '@material-ui/core'

import LoveCanvas from './LoveCanvas'

import { ageVerificationLocalStorageKey } from '../../configuration'

function AgeVerificationCurtain({ children }) {
  const [visible, setVisible] = useState(false)
  const [animate, setAnimate] = useState(false)
  const _ = useStyles()
  const theme = useTheme()

  const isVerified = window.localStorage.getItem(ageVerificationLocalStorageKey)

  function animateCircle() {
    setAnimate(true)
  }

  function endCallback() {
    setVisible(true)
    setTimeout(() => setAnimate(false))
    setTimeout(() => window.localStorage.setItem(ageVerificationLocalStorageKey, true), 1000)
  }

  if (isVerified) {
    return children
  }

  if (visible) {
    return (
      <div
        className={_.fade}
        style={{
          opacity: animate ? 0 : 1,
        }}
      >
        {children}
      </div>
    )
  }

  return (
    <>
      <Box
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        bgcolor={theme.palette.primary.main}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <LoveCanvas
          initialRadius={333}
          expand={animate}
          endCallback={endCallback}
        />
        <Box
          position="absolute"
          top={0}
          bottom={0}
          left={0}
          right={0}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          className={_.fade}
          style={{
            opacity: animate ? 0 : 1,
          }}
        >
          <Typography
            variant="h3"
            component="h1"
          >
            Age verification
          </Typography>
          <Box mt={2} textAlign="center">
            <Typography>
              Romance Education is an adult community that contains age-restricted content.
            </Typography>
            <Typography>
              You must be 18 years old or over to enter.
            </Typography>
          </Box>
          <Box mt={2}>
            <Button
              variant="outlined"
              color="primary"
              onClick={animateCircle}
            >
              I am 18 or older
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}

const useStyles = makeStyles({
  fade: {
    transition: 'opacity 0.5s linear',
  },
})

export default AgeVerificationCurtain
