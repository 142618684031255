import React from 'react'

import Typography from '@material-ui/core/Typography'

function RackTitle({ title }) {
  return (
    <Typography
      variant="h2"
      style={{
        fontSize: 22,
        fontWeight: 700,
        marginLeft: 66,
        marginBottom: 8,
      }}
    >
      {title}
    </Typography>
  )
}

export default RackTitle
