/* global firebase */

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDKkURurujJ5BOhRaZqObmGtk8NZX8leHU',
  authDomain: 'romance-education.firebaseapp.com',
  databaseURL: 'https://romance-education.firebaseio.com',
  projectId: 'romance-education',
  storageBucket: 'romance-education.appspot.com',
  messagingSenderId: '921496591795',
  appId: '1:921496591795:web:09824fc2ea73c8d8383fc6',
  measurementId: 'G-TTH2B7CSXZ',
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.analytics()
