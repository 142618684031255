import React from 'react'
import { QueryRenderer } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import FullScreenSpinner from '../../components/FullScreenSpinner'
import TagsManager from './TagsManager'

import environment from '../../relay-environment'

function TagsManagerQueryRenderer() {
  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query TagsManagerQueryRendererQuery {
          resource {
            ...TagsManager_resource
          }
        }
      `}
      render={({ error, props }) => {
        if (error) {
          return (
            <div>Error! TagsManagerQueryRenderer</div>
          )
        }

        if (!props) {
          return (
            <FullScreenSpinner />
          )
        }

        return (
          <TagsManager resource={props.resource} />
        )
      }}
    />
  )
}

export default TagsManagerQueryRenderer
