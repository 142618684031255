import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import environment from '../relay-environment'
import handleMutationCompletion from '../utils/handleMutationCompletion'

function signInUserWithFacebook({
  facebookUserId,
  email,
  gender,
  birthday,
  pictureUrl,
}) {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment,
      {
        mutation: graphql`
          mutation signInUserWithFacebookMutation($input: signInUserWithFacebookInput!) {
            signInUserWithFacebook(input: $input) {
              viewer {
                id
              }
            }
          }
        `,
        variables: {
          input: {
            facebookUserId,
            email,
            gender,
            birthday,
            pictureUrl,
          },
        },
        onCompleted: handleMutationCompletion(resolve, reject),
        onError: error => reject(error),
      },
    )
  })
}

export default signInUserWithFacebook
