import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

function Chevron({ direction, onClick }) {
  const [isMouseOver, setIsMouseOver] = useState(false)
  const _ = useStyles()

  const isLeft = direction === 'left'
  const Icon = isLeft ? ChevronLeftIcon : ChevronRightIcon

  function handleMouseMove() {
    if (!isMouseOver) {
      setIsMouseOver(true)
    }
  }

  function handleMouseEnter() {
    setIsMouseOver(true)
  }

  function handleMouseLeave() {
    setIsMouseOver(false)
  }

  return (
    <Box
      zIndex={9999999}
      position="absolute"
      left={isLeft ? 0 : null}
      right={isLeft ? null : 0}
      top={0}
      bottom={0}
      width={50}
      display="flex"
      alignItems="center"
      justifyContent="center"
      fontSize={isMouseOver ? 82 : 64}
      onClick={onClick}
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={_.root}
    >
      <Icon fontSize="inherit" />
    </Box>
  )
}

const useStyles = makeStyles({
  root: {
    cursor: 'pointer',
    transition: 'font-size 0.15s ease-in-out',
  },
})

export default Chevron
