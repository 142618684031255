import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

function PillButton({ children, to, onClick }) {
  const _ = useStyles()
  const RootComponent = typeof to === 'string' ? RouterLink : 'div'

  return (
    <RootComponent
      className={_.root}
      to={to}
      onClick={onClick}
    >
      <div className={_.slider} />
      <span className={_.children}>
        {children}
      </span>
    </RootComponent>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    borderColor: theme.palette.primary[500],
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 19,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    cursor: 'pointer',
    userSelect: 'none',
    textDecoration: 'none',
    color: 'inherit',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
    '&:hover': {
      boxShadow: '0 3px 7px rgba(0,0,0,0.25), 0 3px 3px rgba(0,0,0,0.22)',
      '&>div': {
        right: -1,
      },
      '&>span': {
        color: 'white',
        '&>svg': {
          color: 'white',
        },
      },
    },
    '&:active': {
      boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    },
  },
  slider: {
    zIndex: 1,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: -1,
    right: '100%',
    height: 19 * 2,
    backgroundColor: theme.palette.primary[500],
    // transform: 'skew(-11deg)',
    transition: 'all .2s ease-out',
  },
  children: {
    zIndex: 2,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    transition: 'all .2s ease-out',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export default PillButton
